<template src="./ImageGallery.html"/>
<style scoped src="./ImageGallery.scss" lang="scss"/>

<script>
import ImageCard from "../../components/ImageCard/ImageCard";
import ImageViewer from "../../components/ImageViewer/ImageViewer";
import { mapGetters } from "vuex";

export default {
  name: "ImageGallery",
  components: {ImageCard, ImageViewer},
  data() {
    return {
      imageViewer: {
        visible: false,
        index: 0
      }
    }
  },
  computed: {
    ...mapGetters(['thereAreImages', 'imagesLoading', 'imagesCount', 'images', 'imagesLoaded', 'canLoadMore'])
  },
  mounted() {
    this.$store.dispatch('initializeImages');
  },
  methods: {
    loadMoreImages() {
      this.$store.dispatch('loadMoreImages');
    },
    showImage(index) {
      this.imageViewer.index = index;
      this.imageViewer.visible = true;
    }
  }
}
</script>
