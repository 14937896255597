import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// MODULES
import galleryImages from "./galleryImages";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    galleryImages
  }
})
