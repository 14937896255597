<template>
  <b-card no-body class="h-100 imageCard d-flex justify-content-center">
    <b-card-img :src="image.url"/>
  </b-card>
</template>

<script>
export default {
  name: "ImageCard",
  props: {
    image: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
