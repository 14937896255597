import Axios from "axios";

Axios.defaults.baseURL = "https://amministrazione.marcosolfrini.it/graphql";

const state = {
  images: [],
  imagesCount: null,
  loaded: 0,
  interval: 9
};

const mutations = {
  setImagesCount(state, imagesCount) {
    state.imagesCount = imagesCount;
  },
  addImages(state, images) {
    state.images.push(...images);
    state.loaded = state.loaded + state.interval;
  },
};

const actions = {
  getImagesCount({commit}) {
    Axios.post('', {
      query: `
          {
            imagesCount
          }
        `
    }).then((result) => {
      commit('setImagesCount', result.data.data.imagesCount)
    })
  },
  loadMoreImages({commit, state}) {
    if(state.imagesCount !== null && !getters.canLoadMore(state))
      return;

    Axios.post('', {
      query: `
          {
            images (sort: "date:desc", start: ${state.loaded}, limit: ${state.interval}) {
              id,
              createdAt,
              Image {
                url
              }
            }
          }
        `
    }).then((result) => {
      let images = result.data.data.images.map(image => image.Image);
      commit('addImages', images);
    })
  },
  initializeImages({state, dispatch}) {
    if(state.imagesCount !== null)
      return;

    dispatch('loadMoreImages');
    dispatch('getImagesCount');
  }
};

const getters = {
  thereAreImages: state => {
    return state.imagesCount > 0;
  },
  imagesLoading: state => {
    return state.imagesCount === null;
  },
  imagesCount: state => {
    return state.imagesCount;
  },
  images: state => {
    return state.images;
  },
  imagesLoaded: state => {
    return state.loaded;
  },
  canLoadMore: state => {
    return state.loaded < state.imagesCount;
  }
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}

