<template src="./Contacts.html" />
<style src="./Contacts.scss" scoped />

<script>
export default {
  name: "Contacts",
  metaInfo: {
    title: 'Contatti - ODV Marco Solfrini',
    description: "Tutti i contatti dell'Organizzazione Di Volontariato Marco Solfrini"
  }
}
</script>
