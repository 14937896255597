<template>
  <div id="app">
    <div class="m-5" v-if="isInternetExplorer">
      <h2 class="text-center">Ops! Il tuo browser non è compatibile con questo sito.</h2>
      <div class="pt-3">Ti consigliamo di utilizzarne uno più moderno come:</div>
      <ul>
        <li> Google Chrome</li>
        <li> Mozilla Firefox</li>
        <li> Microsoft Edge</li>
        <li> . . .</li>
      </ul>
    </div>
    <div v-else>
      <NavBar/>
      <router-view/>
      <Footer/>
    </div>
  </div>
</template>
<style src="./styles.scss" lang="scss"/>

<script>
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import ImageViewer from "./components/ImageViewer/ImageViewer";

export default {
  name: 'app',
  components: {Footer, NavBar},
  data() {
    let msie = window.navigator.userAgent.indexOf('MSIE ') > 0
    let ie11 = navigator.userAgent.indexOf('Trident') > 0;

    return {
      isInternetExplorer: msie || ie11
    }
  }
}


</script>
