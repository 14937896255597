import Vue from 'vue';
import VueRouter from 'vue-router'
Vue.use(VueRouter);

import Home from "../views/Home/Home";
import About from "../views/About/About";
import NewsAndEvents from "../views/NewsAndEvents/NewsAndEvents";
import SupportUs from "../views/SupportUs/SupportUs";
import TheProject from "../views/TheProject/TheProject";
import Contacts from "../views/Contacts/Contacts";
import EventDetails from "../views/EventDetails/EventDetails";
import ImageGallery from "../views/ImageGallery/ImageGallery";

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: "Home",
      component: Home
    },
    {
      path: '/about',
      name: "About",
      component: About
    },
    {
      path: '/news-eventi',
      name: "NewsAndEvents",
      component: NewsAndEvents
    },
    {
      path: '/dettagli/:eventOrNewsId',
      name: "EventDetails",
      component: EventDetails
    },
    {
      path: '/sostienici',
      name: "SupportUs",
      component: SupportUs
    },
    {
      path: '/ilprogetto',
      name: "TheProject",
      component: TheProject
    },
    {
      path: '/contatti',
      name: "Contacts",
      component: Contacts
    },
    {
      path: '/galleria',
      name: "ImageGallery",
      component: ImageGallery
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : {x: 0, y: 0}
  }
});
