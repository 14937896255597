<template src="./NewsAndEvents.html"/>
<style lang="scss" scoped src="./NewsAndEvents.scss"/>

<script>
import NewsAndEventCard from "../../components/NewsAndEventCard/NewsAndEventCard";

export default {
  name: "NewsAndEvents",
  metaInfo: {
    title: 'News ed eventi - ODV Marco Solfrini'
  },
  components: {
    NewsAndEventCard
  },
  data() {
    return {
      events: null
    }
  },
  computed: {
    loadingEvents() {
      return !this.events;
    },
    noEvents() {
      if (this.events) {
        return this.events.length === 0;
      } else {
        return false;
      }
    },
    thereAreEvents() {
      if (this.events) {
        return this.events.length > 0;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getApiData();
  },
  methods: {
    getApiData() {
      this.$http.post('', {
        query: `
          {
            events (sort: "date:desc") {
              id,
              title,
              date,
              shortDescription,
              isNews,
              coverImage {
                  url
              },
              position,
              createdAt
            }
          }
        `
      }).then((result) => {
        this.events = result.data.data.events;
      })
    }
  }
}
</script>
