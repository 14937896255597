<template src="./ImageViewer.html"/>
<style scoped src="./ImageViewer.scss" lang="scss"/>

<script>

export default {
  name: "ImageViewer",
  data() {
    return {
      // imageIndex: 0
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    images: {
      type: Array,
      required: true
    }
  },
  watch: {
    index: function (newIndex) {
      this.$emit('update:index', newIndex);
    },
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    prevImage() {
      this.$refs.imagesCarousel.prev();
    },
    nextImage() {
      this.$refs.imagesCarousel.next();
    },
  }
}
</script>
