<template src="./NewsAndEventCard.html"/>
<style scoped lang="scss" src="./NewsAndEventCard.scss"/>

<script>
export default {
  name: "NewsAndEventCard",
  props: {
    eventOrNews: {
      type: Object
    }
  },
  computed: {
    dateTime() {
      if (!this.eventOrNews.date && !this.eventOrNews.createdAt) return undefined;
      let date = new Date(this.eventOrNews.date || this.eventOrNews.createdAt);
      let time = this.format(date.getHours()) + ':' + this.format(date.getMinutes());
      return this.format(date.getDate()) + '/' + this.format(date.getMonth() + 1) + '/' + date.getFullYear() + '  ' + time;
    }
  },
  methods: {
    format(dayOrMonth) {
      let string = dayOrMonth.toString();
      if (string.length === 1) {
        return '0' + string;
      }
      return string;
    }
  }
}
</script>
