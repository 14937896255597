import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import router from "./router/router";
import { BootstrapVue } from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import Meta from "vue-meta";
import Axios from "axios";
import store from "./store/store";
import VScrollLock from "v-scroll-lock";

//  Font awesome icons
import { faBasketballBall } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import  { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import  { faArrowRight } from "@fortawesome/free-solid-svg-icons";

library.add(faMapMarkedAlt);
library.add(faBasketballBall);
library.add(faBars);
library.add(faAngleUp);
library.add(faPaperPlane);
library.add(faInstagram);
library.add(faFacebook);
library.add(faNewspaper);
library.add(faCalendarAlt);
library.add(faTimes);
library.add(faArrowLeft);
library.add(faArrowRight);

Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VScrollLock);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Axios.defaults.baseURL = "https://amministrazione.marcosolfrini.it/graphql";
Vue.prototype.$http = Axios;

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});
