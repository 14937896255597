<template src="./Home.html" />
<style src="./Home.scss" lang="scss" scoped />

<script>
import NewsAndEventCard from"../../components/NewsAndEventCard/NewsAndEventCard";

export default {
  name: "Home",
  metaInfo: {
    title: 'ODV Marco Solfrini',
    description: 'L\'Organizzazione di Volontariato Marco Solfrini si ripropone di ricordare e onorare il cestista bresciano attraverso il finanziamento delle iniziative sociali.'
  },
  components: {
    NewsAndEventCard
  },
  data() {
    return {
      lastEventsAndNews: null,
      showHomeBrand: true
    }
  },
  computed: {
    loadingEvents() {
      return !this.lastEventsAndNews;
    },
    thereAreEvents() {
      if (this.lastEventsAndNews) {
        return this.lastEventsAndNews.length > 0;
      } else {
        return false;
      }
    },
    noEvents() {
      if (this.lastEventsAndNews) {
        return this.lastEventsAndNews.length === 0;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$root.$on('showHomeBrand', () => {
      this.showHomeBrand = true;
    });
    this.$root.$on('hideHomeBrand', () => {
      this.showHomeBrand = false;
    });

    this.getLastEventsAndNews();
  },
  methods: {
    getLastEventsAndNews() {
      this.$http.post('', {
        query: `
          {
            events (limit: 3, sort: "date:desc") {
              id,
              title,
              date,
              shortDescription,
              isNews,
              coverImage {
                  url
              },
              position,
              createdAt
            }
          }
        `
      }).then((result) => {
        this.lastEventsAndNews = result.data.data.events;
      })
    },
    scrollTo(elementRef) {
      const element = this.$refs[elementRef];
      const top = element.offsetTop - 145;

      window.scrollTo(0, top);
    }
  }
}
</script>
