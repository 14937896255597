<template src="./About.html" />
<style src="./About.scss" lang="scss" scoped />
vole
<script>
  export default {
    name: "About",
    mounted() {
      this.$root.$on('navbarToggle', (state) => {
        let promoBlock = this.$refs['promoBlock'];
        if (promoBlock !== undefined) {
          if (state) {
            promoBlock.style.marginTop = '0px';
          } else {
            promoBlock.style.marginTop = '-126px';
          }
        }
      })
    },
    metaInfo: {
      title: 'Chi siamo - ODV Marco Solfrini',
      description: 'Chi siamo? Cosa facciamo? Chi è marco solfrini?'
    }
  }
</script>
