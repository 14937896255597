<template src="./EventDetails.html"/>
<style scoped lang="scss" src="./EventDetails.scss" />

<script>
import ImageViewer from "../../components/ImageViewer/ImageViewer";

export default {
  name: "EventDetails",
  components: {ImageViewer},
  data() {
    return {
      imageViewer: {
        visible: false,
        index: 0
      },
      eventOrNews: null,
      error: null
    }
  },
  mounted() {
    this.getEventOrNewsData();
  },
  computed: {
    dateTime() {
      if (!this.eventOrNews.date && !this.eventOrNews.createdAt) return undefined;
      let date = new Date(this.eventOrNews.date || this.eventOrNews.createdAt);
      let time = this.format(date.getHours()) + ':' + this.format(date.getMinutes());
      return this.format(date.getDate()) + '/' + this.format(date.getMonth() + 1) + '/' + date.getFullYear() + '  ' + time;
    },
    hasOnlyOneImage() {
      return this.eventOrNews.images.length === 1;
    },
    hasPosition() {
      return !this.eventOrNews.isNews && this.dateTime
    }
  },
  methods: {
    getEventOrNewsData() {
      this.$http.post('', {
        query: `
          query GetEventsDetails {
            event(id: "${this.$route.params.eventOrNewsId}") {
              id,
              title,
              date,
              description,
              isNews,
              coverImage {
                  url
              },
              position,
              createdAt,
              images {
                  url
              }
            }
          }
        `
      }).then((response) => {
        this.eventOrNews = response.data.data.event;
        if (this.eventOrNews.coverImage) {
          this.eventOrNews.images.unshift(this.eventOrNews.coverImage)
        }
      }).catch(() => {
        this.error = "L'evento cercato non è stato trovato.";
        this.$bvModal.show('errorModal');
      });
    },
    format(dayOrMonth) {
      let string = dayOrMonth.toString();
      if (string.length === 1) {
        return '0' + string;
      }
      return string;
    },
    prevImage() {
      this.$refs.eventCarousel.prev();
    },
    nextImage() {
      this.$refs.eventCarousel.next();
    },
    openImageViewer(index) {
      this.imageViewer.index = index;
      this.imageViewer.visible = true;
    }
  }
}
</script>
