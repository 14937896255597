<template src="./NavBar.html"/>
<style src="./NavBar.scss" lang="scss" scoped/>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      toggleState: false,
      routes: [
        {name: 'Home', link: '/'},
        {name: 'About', link: '/about'},
        {name: 'News ed eventi', link: '/news-eventi'},
        {name: 'Il Progetto', link: '/ilprogetto'},
        {name: 'Sostienici', link: '/sostienici'},
        {name: 'Galleria', link: '/galleria'},
        {name: 'Contatti', link: '/contatti'}
      ],
      isTall: true,
      showNavbarBrand: false
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleNavbarBrand);
  },
  mounted() {
    this.handleNavbarBrand();
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.toggleState = isJustShown;
      this.onToggle();
    })
  },
  computed: {
    isTransparent() {
      return this.isTall && !this.toggleState && (this.isAbout || this.isHome);
    },
    isTextLight() {
      return this.isTransparent && !this.isSupportUs;
    },
    isAbout() {
      return this.$route.name === 'About';
    },
    isHome() {
      return this.$route.name === 'Home';
    },
    isSupportUs() {
      return this.$route.name === 'SupportUs';
    }
  },
  watch: {
    $route(to, from) {
      this.handleNavbarBrand();
    }
  },
  methods: {
    handleScroll() {
      this.handleNavbarBrand();
      this.isTall = this.isTall ? !(document.documentElement.scrollTop > 100) : !(document.documentElement.scrollTop > 60);
    },
    handleNavbarBrand() {
      if (!this.isHome) {
        this.showNavbarBrand = true;
      } else {
        if (window.innerWidth > 768) {
          if (this.isTall) {
            this.showNavbarBrand = false;
            this.$root.$emit('showHomeBrand');
          } else {
            this.showNavbarBrand = true;
            this.$root.$emit('hideHomeBrand');
          }
        } else {
          this.showNavbarBrand = true;
        }
      }
    },
    onToggle() {
      this.$root.$emit('navbarToggle', this.toggleState);
    },
    changedRoute() {
      this.toggleState = false;
    }
  }
}
</script>
