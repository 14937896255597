<template src="./SupportUs.html"/>
<style scoped lang="scss" src="./SupportUs.scss"/>

<script>
  export default {
    name: "SupportUs",
    metaInfo: {
      title: 'Sostienici - ODV Marco Solfrini',
      description: "Per poter realizzare le vie del cuore abbiamo bisogno dell’aiuto e del supporto di tutte quelle realtà che hanno conosciuto Marco, il suo impegno nel sociale e che hanno a cuore il benessere del prossimo"
    }
  }
</script>
