<template src="./TheProject.html"/>
<style src="./TheProject.scss" lang="scss" scoped />

<script>
  export default {
    name: "TheProject",
    metaInfo: {
      title: 'Il Progetto - ODV Marco Solfrini',
      description: "La diffusione dei defibrillatori: perchè e cosa stiamo portando avanti?"
    }
  }
</script>
